import EventService from "./eventService";

interface ShowConfirmationEventDetail {
  details: ModalDetails;
  confirmCallback?: () => void;
  cancelCallback?: () => void;
}

interface ModalDetails {
  id: string;
  title?: string;
  message?: string;
  size?: string;
  confirmButtonText?: string;
  confirmButtonTone?: string;
  cancelButtonText?: string;
}

// Function to provide default values for ModalDetails
const getDefaultModalDetails = (details: ModalDetails): ModalDetails => {
  return {
    confirmButtonText: "Confirm",
    cancelButtonText: "Cancel",
    ...details
  };
};

function ConfirmationModal({
  details,
  confirmCallback,
  cancelCallback
}: ShowConfirmationEventDetail) {
  const defaultDetails = getDefaultModalDetails(details);
  const {
    title,
    message,
    confirmButtonText,
    confirmButtonTone,
    cancelButtonText,
    size
  } = defaultDetails;

  // Create the modal HTML structure
  const modalHtml = `
    <ui-modal id="modal" variant="${size ?? "small"}">
      <ui-title-bar title="${title}">
        <button id="confirm-button" tone="${confirmButtonTone}" variant="primary">${confirmButtonText}</button>
        <button id="cancel-button">${cancelButtonText}</button>
      </ui-title-bar>
      <p style="text:center; padding:1rem 1.5rem;" id="modal-message">${message ?? ""}</p>
    </ui-modal>
  `;

  // Inject the modal into the DOM
  document.body.insertAdjacentHTML("beforeend", modalHtml);

  // Add event listeners to the buttons
  const confirmButton = document.getElementById(
    "confirm-button"
  ) as HTMLButtonElement;
  const cancelButton = document.getElementById(
    "cancel-button"
  ) as HTMLButtonElement;
  const modal = document.getElementById("modal") as HTMLElement & {
    show: () => void;
    hide: () => void;
  };

  if (confirmButton && cancelButton && modal) {
    confirmButton.onclick = function () {
      if (confirmCallback) {
        confirmCallback();
      }
      modal.hide();
    };

    cancelButton.onclick = function () {
      if (cancelCallback) {
        cancelCallback();
      }
      modal.hide();
    };

    modal.show();
  } else {
    console.error("Modal or buttons not found in the DOM.");
  }
}

export default function () {
  const callback = function (event: Event) {
    const customEvent: any = event as CustomEvent<ShowConfirmationEventDetail>;

    ConfirmationModal(customEvent);
  };
  EventService.on("showConfirmation", callback);
}
